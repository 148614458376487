export default [
  {
    key: 'contabilidad',
    path: '/contabilidad/',
  },
  {
    key: 'impuestos',
    path: '/impuestos/',
  },
  {
    key: 'bpo',
    path: '/bpo/',
  },
  {
    key: 'internacional',
    path: '/internacional/',
  },
  {
    key: 'advisory',
    path: '/advisory/',
  },
  {
    key: 'sector-financiero',
    path: '/sector-financiero/',
  },
  {
    key: 'legal',
    path: '/legal/',
  },
  {
    key: 'news',
    path: '/news/',
  },
  {
    key: 'as-xpert',
    path: '/as-xpert/',
  },
  {
    key: 'marketing-contenido-digital',
    path: '/marketing-contenido-digital/',
  },
  {
    key: 'technologies',
    path: '/technologies/',
  },
  {
    key: 'omnium',
    path: '/omnium/',
  },
  {
    key: 'health',
    path: '/health/',
  },
  {
    key: 'formacion-profesional',
    path: '/formacion-profesional/',
  },
  {
    key: 'sustentabilidad',
    path: '/sustentabilidad/',
  },
]
