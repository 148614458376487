import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { Row, Col } from 'react-styled-flexboxgrid'
import ourGroupSolutions from '../utils/ourGroupSolutions'
import FooterContact from '../components/FooterContact'
import image_background from '../images/solutions/fiscal.png'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import icon_AS from '../images/icon_as.png'

const OurGroup = props => {
  const onRenderItem = ({ key }, index) => {
    return (
      <Row key={index} style={{ margin: '1rem 0' }}>
        <Col xs={12}>
          <Row bottom="xs">
            <img
              src={icon_AS}
              height={25}
              alt="Icon AS Consulting"
              style={{ marginRight: '0.5rem' }}
            />
            <b>
              <FormattedHTMLMessage id={`ourGroup.solutions.${key}.title`} />
            </b>
          </Row>
        </Col>
        <Col xs={12} style={{ marginTop: '0.5rem' }}>
          <FormattedHTMLMessage id={`ourGroup.solutions.${key}.description`} />
        </Col>
      </Row>
    )
  }

  return (
    <Layout
      title="Nuestro grupo"
      description="En AS Consulting Group, firma miembro de SMS Latinoamérica brindamos soluciones especializadas
    en materia contable, fiscal y legal."
      {...props}
    >
      <SectionHeaderImage image={image_background} position="90% 0" />
      <Section height="600px">
        <TitleSection>
          <FormattedMessage id="ourGroup.title" />
        </TitleSection>
        <Row center="xs" xs={12}>
          <Col xs={12} lg={8}>
            <p>
              <FormattedHTMLMessage id="ourGroup.description" />
            </p>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={12} md={5} lg={4}>
            {ourGroupSolutions.slice(0, 9).map(onRenderItem)}
          </Col>
          <Col xs={12} md={5} lg={4}>
            {ourGroupSolutions.slice(9).map(onRenderItem)}
          </Col>
        </Row>
      </Section>
      <FooterContact />
    </Layout>
  )
}

export default OurGroup
